import domReady from '~/assets/js/ready'
import Tabs from '~/assets/js/tabs'
import ContentTabs from '~/assets/js/content-tabs'
import Toggler from '~/assets/js/toggler'
import Navbar from '~/assets/js/navbar'
import Glide from '@glidejs/glide'
import Map from '~/assets/js/map'
import RatesSwitch from '~/assets/js/rates'
import ssm from '~/assets/js/ssm/ssm'
import ContentOverflow from '~/assets/js/content-overflow'
import Tooltip from '~/assets/js/tooltip'
import ModalSlider from '~/assets/js/sliders'

domReady(() => {
  // Tabs
  const tabs = new Tabs()

  // Content Tabs
  const contentTabs = new ContentTabs()

  // Content overflow
  Array.from(document.querySelectorAll('[data-content-overflow]')).forEach(el => {
    const contentOverflow = new ContentOverflow(el)
  })

  // Tooltips
  Array.from(document.querySelectorAll('[data-tooltip]')).forEach(el => {
    const tooltip = new Tooltip(el)
  })

  // Togglers
  Array.from(document.querySelectorAll('[data-toggle]')).forEach(el => {
    const toggler = new Toggler(el)
  })

  // Sliders
  Array.from(document.querySelectorAll('[data-slider]')).forEach(el => {
    const slider = new ModalSlider(el)
  })

  // Navbar
  const navbar = new Navbar()

  // Floating CTA
  Array.from(document.querySelectorAll('.floating-cta')).forEach(el => {
    setTimeout(() => {
      el.classList.add('floating-cta--active')
    }, 5000)
  })

  let mountedSlides = []
  const slides = Array.from(document.querySelectorAll('.glide'))

  if (slides.length) {
    ssm.addState({
      id: 'mobile',
      query: '(max-width: 767px)',
      onEnter () {
        slides.forEach(el => {
          mountedSlides.push(new Glide(el, {
            perView: +el.getAttribute('data-per-view'),
          }))

          mountedSlides[mountedSlides.length - 1].mount()
        })
      },
      onLeave () {
        if (mountedSlides.length) {
          mountedSlides.forEach(el => {
            el.destroy()
          })
        }
      }
    })
  }

  const maps = document.querySelectorAll('[data-map]')
  if (maps && maps.length) {
    for (let i = 0; i < maps.length; i++) {
      // eslint-disable-next-line
      const map = new Map(maps[i])
    }
  }

  Array.from(document.querySelectorAll('[data-switch]')).forEach(el => {
    const ratesSwitch = new RatesSwitch(el)
  })
})
